<template>
    <div class="appointment-simple-slot-picker">
        <div class="mb-4 mb-md-6 appointment-simple-slot-picker__input">
            <RisifySelect
                v-model="selected_date"
                @update:model-value="selected_slot_id = ''"
                :items="days_items"
                ref="days_select_ref"
                :placeholder="availability_slots_loading ? 'Pobieranie danych...' : 'Wybierz datę'"
                :rules="[isRequired]"
                :outlined="outlined"
                :dense="dense"
                label="Wybierz datę"
                :disabled="disabled || availability_slots_loading || loading"
                show-asterisk
            ></RisifySelect>
            <Transition name="fade">
                <div
                    v-if="availability_slots_loading"
                    class="appointment-simple-slot-picker__ioverlay"
                >
                    <Spinner
                        size="20"
                        color="primary"
                        width="3"
                    />
                </div>
            </Transition>
        </div>
        <RisifySelect
            v-if="selected_date"
            v-model="selected_slot_id"
            :items="selected_day_slot_items"
            ref="slots_select_ref"
            placeholder="Wybierz termin"
            :rules="[isRequired]"
            :outlined="outlined"
            :dense="dense"
            label="Wybierz termin"
            :disabled="disabled || availability_slots_loading || loading"
            show-asterisk
        ></RisifySelect>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from "vue";

import { useAuthStore } from "@/stores/auth";
import { displayAlert } from "@/main";

import { IO, joinPredefinedRoom, leavePredefinedRoom } from "@/plugins/socketIo";
import { httpJsonRequest } from "@/plugins/fetchApi";

import RisifySelect from "@/components/form-inputs/RisifySelect.vue";
import { RisifyInputBaseExpose } from "@/components/form-inputs/RisifyInput.vue";
import {
    RisifySelectOption,
    RisifySelectValue
} from "@/components/form-inputs/RisifySelectElement.vue";
import Spinner from "../loaders/Spinner.vue";

import moment from "@/helpers/moment";
import { isRequired } from "@/helpers/validators";
import { capitalizeString } from "@/helpers/formatters";

import { ProductAvailabilitySlot, ApiProductsAvailabilitySlotsResponse } from "@/types/product";
import {
    IoEvent,
    IoPredefinedRooms,
    IoCheckoutSessionSlotsAvailabilityUpdate,
    IoCalendarSlotsRefreshedPayload
} from "@/types/socket-io";

/*###########
### SETUP ###
########## */
interface AppointmentSimpleSlotPickerProps {
    product: string;
    therapist: string;
    outlined?: boolean;
    dense?: boolean;
    loading?: boolean;
    disabled?: boolean;
}

type AppointmentSimpleSlotPickerValue = {
    slot_id: RisifySelectValue;
    date_time_from: string;
    date_time_to: string;
};

export type AppointmentSimpleSlotPickerExpose = {
    getValue: () => AppointmentSimpleSlotPickerValue;
    validate: () => boolean;
};

const auth_store = useAuthStore();
const props = defineProps<AppointmentSimpleSlotPickerProps>();
defineExpose<AppointmentSimpleSlotPickerExpose>({ getValue, validate });
/*#############
### VARIABLES ###
############ */
const selected_date = ref<RisifySelectValue>("");

const selected_slot_id = ref<RisifySelectValue>("");
const availability_slots = ref<ProductAvailabilitySlot[]>([]);
const availability_slots_loading = ref(false);
const refresh_tokens = ref<string[]>([]);

const days_select_ref = ref<RisifyInputBaseExpose>();
const slots_select_ref = ref<RisifyInputBaseExpose>();

/*##############
### COMPUTED ###
############# */
const days_items = computed(() => {
    const S = new Set<string>();

    availability_slots.value.forEach(slot => {
        const date = moment.tz(slot.from, auth_store.getUserTimezone()).format("DD.MM.YYYY");
        S.add(date);
    });

    return Array.from(S).map(day => {
        return {
            text: capitalizeString(
                moment
                    .tz(day, "DD.MM.YYYY", auth_store.getUserTimezone())
                    .format("dddd, DD.MM.YYYY")
            ),
            value: day
        } as RisifySelectOption<{}>;
    });
});

const selected_day_slot_items = computed(() => {
    return availability_slots.value
        .filter(slot => {
            const date = moment.tz(slot.from, auth_store.getUserTimezone()).format("DD.MM.YYYY");
            return date === selected_date.value;
        })
        .map(slot => {
            return {
                text: `${moment
                    .tz(slot.from, auth_store.getUserTimezone())
                    .format("HH:mm")} - ${moment
                    .tz(slot.to, auth_store.getUserTimezone())
                    .format("HH:mm")}`,
                value: slot.slot_id,
                disabled: slot.quantity === 0
            } as RisifySelectOption<{}>;
        });
});

/*#############
### METHODS ###
############ */
async function getSlots() {
    availability_slots_loading.value = true;

    try {
        const res = await httpJsonRequest<ApiProductsAvailabilitySlotsResponse>(
            `/products/${props.product}/availability-slots?therapist=${props.therapist}`
        );

        if (res.success) {
            availability_slots.value = [...res.availability_slots];
            if (res.refresh_token && !refresh_tokens.value.includes(res.refresh_token)) {
                refresh_tokens.value.push(res.refresh_token);
            }
        }
    } catch (e) {
        throw e;
    }

    availability_slots_loading.value = false;
}

function getValue() {
    const selected_slot = availability_slots.value.find(
        it => it.slot_id === selected_slot_id.value
    );
    if (selected_slot) {
        return {
            slot_id: selected_slot_id.value,
            date_time_from: selected_slot.from,
            date_time_to: selected_slot.to
        };
    } else {
        return {
            slot_id: selected_slot_id.value,
            date_time_from: "",
            date_time_to: ""
        };
    }
}

function validate() {
    const A: boolean[] = [
        days_select_ref.value ? days_select_ref.value.validate() : false,
        slots_select_ref.value ? slots_select_ref.value.validate() : false
    ];

    if (A.indexOf(false) !== -1) {
        return false;
    } else {
        return true;
    }
}

function onSocketIoSlotsBooked(e: IoCheckoutSessionSlotsAvailabilityUpdate) {
    if (e.ref_id == auth_store.user?._id) return;

    const M = [];
    for (let i = 0; i < e.slot_ids.length; i++) {
        const ix = availability_slots.value.findIndex(it => it.slot_id == e.slot_ids[i]);
        if (ix !== -1) {
            availability_slots.value.splice(ix, 1, {
                ...availability_slots.value[ix],
                quantity: 0
            });
        }

        if (selected_slot_id.value === e.slot_ids[i]) {
            M.push(e.slot_ids[i]);
            selected_slot_id.value = "";
        }
    }
    if (M.length > 0) {
        displayAlert.error({
            title: "Zmiana dostępności terminów",
            message:
                "Wybrany przez Ciebie termin został właśnie zarezerwowany przez innego użytkownika i nie jest już dostępny. Lista wybranych terminów została odpowiednio zaktualizowana.",
            duration: 8000
        });
    }
}
function onSocketIoSlotsReleased(e: IoCheckoutSessionSlotsAvailabilityUpdate) {
    if (e.ref_id == auth_store.user?._id) return;

    for (let i = 0; i < e.slot_ids.length; i++) {
        const ix = availability_slots.value.findIndex(it => it.slot_id == e.slot_ids[i]);
        if (ix !== -1) {
            availability_slots.value.splice(ix, 1, {
                ...availability_slots.value[ix],
                quantity: 1
            });
        }
    }
}
async function onSocketIoSlotsRefreshed(e: IoCalendarSlotsRefreshedPayload) {
    if (refresh_tokens.value.includes(e.token)) {
        await getSlots();
    }
}

/*######################
### LIFECYCLES HOOKS ###
##################### */
onMounted(async () => {
    await getSlots();

    joinPredefinedRoom(IoPredefinedRooms.CALENDAR);
    IO.on(IoEvent.CALENDAR_SLOTS_BOOKED, onSocketIoSlotsBooked);
    IO.on(IoEvent.CALENDAR_SLOTS_RELEASED, onSocketIoSlotsReleased);
    IO.on(IoEvent.CALENDAR_SLOTS_REFRESHED, onSocketIoSlotsRefreshed);
});

onUnmounted(() => {
    leavePredefinedRoom(IoPredefinedRooms.CALENDAR);
    IO.off(IoEvent.CALENDAR_SLOTS_BOOKED, onSocketIoSlotsBooked);
    IO.off(IoEvent.CALENDAR_SLOTS_RELEASED, onSocketIoSlotsReleased);
    IO.off(IoEvent.CALENDAR_SLOTS_REFRESHED, onSocketIoSlotsRefreshed);
});
</script>
